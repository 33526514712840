import { getUpcomingWorkshops } from 'api/workshops';
import { useQuery } from 'react-query';

export const useUpcomingWorkshopsQuery = () => {
  const { isLoading, data } = useQuery('upComingWorkshops', getUpcomingWorkshops);
  return {
    isLoading,
    data,
  };
};
