export const ROUTES = {
  home: '/',
  event: 'event/:eventKey',
  checkout: 'checkout',
  summarySuccess: 'summary/success',
  summaryCancel: 'summary/cancel',
  summaryFailed: 'summary/failed',
  featureflag: 'featureflags',
  ticketGenerator: 'ticket-generator/:ticketId',
  termsOfUse: 'terms-of-use',
  privacyPolicy: 'privacy-policy',
  aboutUs: 'about-us',
  profile: 'profile',
  history: 'history',
  concerts: 'concerts',
  singleConcert: 'concert/:eventKey',
  matches: '/matches',
  workshops: '/workshops',
  test: 'test',
} as const;
