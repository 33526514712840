import { useMediaQuery } from '@chakra-ui/react';
import { Box, Flex, Heading, Spinner, VStack } from '@chakra-ui/react';
import { GameCard } from 'components/GameCard/GameCard';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useUpcomingMatchesQuery } from './hooks';

export const Upcoming = () => {
  const navigate = useNavigate();

  const { isLoading, data } = useUpcomingMatchesQuery();

  const handleCardClick = (eventKey: string) => {
    navigate('/event/' + eventKey);
  };
  const [isSmallMobile] = useMediaQuery('(max-width: 280px)');
  return (
    <Box
      as='section'
      bg='BG100'
      px={isSmallMobile ? '0px' : '16px'}
      py={{ base: '24px', md: '36px' }}
      color='dark.D900'
      textAlign={{ base: 'center', md: 'right' }}
      aria-labelledby='headingUpcoming'
      width='100vw'
    >
      <VStack width='100%' justifyContent='center' alignItems='center'>
        <Heading
          id='headingUpcoming'
          mb='24px'
          variant={{ base: 'headingMedium', md: 'headingLarge' }}
          textAlign={{ base: 'center', lg: 'right' }}
        >
          <FormattedMessage defaultMessage='Upcoming matches' id='homepage.upcoming.heading' />
        </Heading>
        {isLoading ? (
          <Flex align='center' justify='center' mt='50px' mb='50px'>
            <Spinner color='green.G50' size='xl' thickness='4px' />
          </Flex>
        ) : data?.items.length ? (
          <Flex
            as='ul'
            justifyContent='center'
            alignItems='center'
            listStyleType='none'
            flexDirection={{ base: 'column', md: 'row' }}
            flexWrap='wrap'
            width='100%'
          >
            {data.items.map((match) => (
              <GameCard
                {...match}
                key={match.id}
                onCardClick={() => handleCardClick(match.eventKey)}
              />
            ))}
          </Flex>
        ) : (
          <FormattedMessage defaultMessage='No upcoming matches' id='homepage.upcoming.nomatches' />
        )}
      </VStack>
    </Box>
  );
};
