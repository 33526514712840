import { Box, Grid, GridItem, Hide, Text, VStack } from '@chakra-ui/react';
import { useCartContext } from 'context/CartContext/CartContext';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useNavigate, Navigate } from 'react-router-dom';
import { Header } from './Header/Header';
import { ModalOpenQiCardInstructions } from './ModalOpenQiCardInstructions/ModalOpenQiCardInstructions';
import { ModalSmartphoneInstructions } from './ModalSmartphoneInstructions/ModalSmartphoneInstructions';
import { QiCardInstructions } from './QiCardInstructions/QiCardInstructions';
import { SmartphoneInstructions } from './SmartphoneInstructions/SmartphoneInstructions';
import { TicketHolderForm, FormValues } from './TicketHolderForm/TicketHolderForm';
import { TicketSummary } from './TicketSummary/TicketSummary';
import { useCheckoutMutation } from './hooks/useCheckoutMutation';
import { DIALING_CODE_IRAQ } from 'utils/constants';
import { LoaderOverlay } from 'components/LoaderOverlay/LoaderOverlay';
import { useHoldTokenSessionTimerContext } from 'context/HoldTokenSessionTimerContext/HoldTokenSessionTimerContext';
import { ModalReservationExpired } from './ModalReservationExpired/ModalReservationExpired';
import { useIsAuthenticated } from 'react-auth-kit';
import { ModalLogin } from 'components/ModalLogin/ModalLogin';
import { ConcertResponse, MatchResponse } from 'api/responseTypes';
import { ConcertTicketSummary } from './TicketSummary/ConcertTicketSummary';
import { toast } from 'utils/toast';
import { sendOrder, sendOrderNumber } from 'api/QiPaymentOrders';
import { sendOrderSuperQi } from 'api/AlipayPaymentOrder';
import { captureException } from '@sentry/react';
import { useAuthCode } from 'context/AuthCodeContext/AuthCodeContext';
import useHylideBridge from 'hooks/useHylideBridge';

export const CheckoutPage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isAuthenticated = useIsAuthenticated();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const { tickets, holdToken, eventKey, eventId } = useCartContext();
  const [totalPrice, setTotalPrice] = useState(0);
  const [isSmartphoneInstructionsModalOpen, setIsSmartphoneInstructionsModalOpen] = useState(false);
  const [isQiCardInstructionsModalOpen, setIsQiCardInstructionsModalOpen] = useState(false);
  const cachedMatchData: MatchResponse | undefined = queryClient.getQueryData(`event-${eventKey}`);
  const cachedConcertData: ConcertResponse | undefined = queryClient.getQueryData(
    `concert-${eventKey}`,
  );

  const { holdTokenSessionTimer } = useHoldTokenSessionTimerContext();
  const dialingCode = localStorage.getItem('dialingCode') || DIALING_CODE_IRAQ;
  const { mutate, status } = useCheckoutMutation();
  const handleOpenSmartphoneModal = () => setIsSmartphoneInstructionsModalOpen(true);
  const handleOpenQiCardInstructionsModal = () => setIsQiCardInstructionsModalOpen(true);
  const handleCloseModal = () => {
    setIsSmartphoneInstructionsModalOpen(false);
    setIsQiCardInstructionsModalOpen(false);
  };

  const handleCloseAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  interface PaymentSuccessProps {
    resultCode: string;
  }

  const hylideBridge = useHylideBridge();
  const { userToken, authCode } = useAuthCode();

  useEffect(() => {
    let sum = 0;
    for (const ticket of tickets) {
      sum += ticket.price;
    }
    setTotalPrice(sum);
  }, [tickets, eventId]);
  const [isLoading, setIsLoading] = useState(false);
  if (!hylideBridge) {
    return null;
  }

  const onSubmit = async (data: FormValues) => {
    const theHoldedToken = holdToken ? holdToken : 'empty';
    const ticketHoldersData = data.ticketHolders.map((holder, idx) => ({
      sector: tickets[idx].sector,
      price: tickets[idx].price,
      holderPhone:
        dialingCode +
        (holder.phoneNumber.startsWith('0')
          ? holder.phoneNumber.slice(1) // Remove the first character ('0')
          : holder.phoneNumber),
      holderName: holder.name,
      gate: tickets[idx].entrance ? tickets[idx].entrance : 'main',
      categoryKey: tickets[idx].categoryKey,
    }));

    if (!userToken && authCode === null) {
      if (!isAuthenticated()) {
        setIsAuthModalOpen(true);
        return;
      }

      if (data?.ticketHolders && eventId) {
        const formattedDate = {
          eventInfoId: 1,
          eventId: eventId,
          holdToken: theHoldedToken,
          tickets: ticketHoldersData,
        };
        mutate(formattedDate);
      }
    } else if (userToken) {
      setIsLoading(true);
      try {
        const response = await sendOrder({
          eventInfoId: 1,
          eventId: eventId,
          holdToken: theHoldedToken,
          tickets: ticketHoldersData,
          paymentMethod: 'QI_SERVICES_PAYMENT_GATEWAY',
        });
        toast({
          status: 'info',
          description: response.status,
        });

        if (response && response.status && response.status >= 400 && response.status < 500) {
          toast({
            title: 'Error',
            description: response.message,
            status: 'error',
          });
          captureException(new Error(response.message));
        } else {
          window.qi.purchase(
            {
              price: totalPrice,
              description: '',
              timeToLiveMillis: 60000,
              merchantTransactionID: response.orderNumber as string,
            },
            async () => {
              sendOrderNumber({ order_number: response.orderNumber }).then((res: any) => {
                if (res) {
                  toast({
                    status: 'success',
                    description: res,
                  }),
                    toast({
                      status: 'success',
                      description: 'تمت عملية الشراء بنجاح',
                    });
                  navigate('/');
                }
              });
            },
          );
        }
      } catch (error: any) {
        toast({
          status: 'error',
          description: JSON.stringify(error.response.data.message),
        });
        captureException(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      try {
        const response = await sendOrderSuperQi({
          eventId: eventId,
          eventInfoId: 0,
          holdToken: theHoldedToken,
          tickets: ticketHoldersData,
          paymentMethod: 'MINI_PROGRAM',
        });
        if (authCode) {
          hylideBridge.tradePay({
            paymentUrl: response.paymentLink,
            success: (res: PaymentSuccessProps) => {
              if (res.resultCode === '6001') {
                return toast({
                  title: 'تم إلغاء الدفع',
                  description: 'تم إلغاء الدفع من قبل المستخدم',
                  status: 'info',
                });
              } else if (res.resultCode === '9000') {
                toast({
                  status: 'success',
                  description: 'تمت عملية الشراء بنجاح',
                });
                navigate('/');
              } else if (res.resultCode) {
                return (
                  toast({
                    title: 'يوجد خطأ في العملية',
                    description: `العملية غير ناجحة, يرجى المحاولة في وقت اخر`,
                    status: 'error',
                  }),
                  captureException('Payment is failed', res.resultCode as any)
                );
              }
            },
            fail: (err: any) => {
              toast({
                title: 'Payment Failed in mini app',
                description: err.message,
                status: 'error',
              });
              captureException('Payment Failed in the mini app', err.message);
            },
          });
        }
      } catch (error: any) {
        captureException(error);
        toast({
          status: 'error',
          description: JSON.stringify(error.response.data.message),
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!cachedMatchData && !cachedConcertData) {
    return <Navigate to='/' replace={true} />;
  }
  return (
    <>
      {(status === 'loading' || status === 'success') && <LoaderOverlay />}
      <Header />
      <Box width='100vw' mx='auto' px={4} mb='40px'>
        <Grid
          mx='auto'
          maxW='1440px'
          templateColumns={{ base: '1fr', md: '1fr 28%' }}
          gap='24px'
          mt={{ base: '-36px', md: '-100px' }}
          pb='40px'
        >
          <GridItem>
            <TicketHolderForm onSubmit={onSubmit} />
            <Hide>
              {userToken === undefined && authCode === null && (
                <VStack mt='40px' align='end' spacing='24px'>
                  <Text variant='bodyLargestRegular'>
                    <FormattedMessage
                      id='checkout.paymentDetails'
                      defaultMessage={'Payment Details'}
                    />
                  </Text>
                  <QiCardInstructions onModalOpen={handleOpenQiCardInstructionsModal} />
                </VStack>
              )}
            </Hide>
          </GridItem>
          <GridItem>
            {cachedMatchData ? (
              <TicketSummary {...cachedMatchData.match} />
            ) : cachedConcertData ? (
              <ConcertTicketSummary {...cachedConcertData.concert} isLoading={isLoading} />
            ) : (
              <div></div>
            )}

            <SmartphoneInstructions onModalOpen={handleOpenSmartphoneModal} />
          </GridItem>
        </Grid>
      </Box>

      <ModalReservationExpired isOpen={holdTokenSessionTimer === 0} />
      <ModalLogin isOpen={isAuthModalOpen} onClose={handleCloseAuthModal} />
      <ModalSmartphoneInstructions
        isOpen={isSmartphoneInstructionsModalOpen}
        onClose={handleCloseModal}
      />
      <ModalOpenQiCardInstructions
        isOpen={isQiCardInstructionsModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
};
