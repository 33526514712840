import { useMediaQuery } from '@chakra-ui/react';
import { Badge, Box, Divider, Flex, Image, Text } from '@chakra-ui/react';
import { MatchInfo, MatchState } from 'api/responseTypes';
import { ReactComponent as mapPinSVG } from 'assets/map-pin.svg';
import { ReactComponent as PlaceholderTeamLogo } from 'assets/placeholder.svg';
import { FormattedMessage } from 'react-intl';

interface GameCardProps extends Omit<MatchInfo, 'eventKey'> {
  onCardClick: () => void;
}

export const GameCard = ({
  state,
  onCardClick,
  date,
  time,
  stadium,
  startingPrice,
  currency,
  teamA,
  teamB,
  title,
  subtitle,
}: GameCardProps) => {
  const [isSmallMobile] = useMediaQuery('(max-width: 280px)');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return isSmallMobile ? (
    <Box
      bg='white'
      color={state === MatchState.SOLD_OUT ? 'dark.D500' : 'dark.D900'}
      textAlign='right'
      width='100vw'
      borderRadius='16px'
      borderColor='white'
      borderWidth='1px'
      _hover={{ borderColor: state === MatchState.SOLD_OUT ? 'dark.D500' : 'green.G500' }}
      cursor={state === MatchState.SOLD_OUT ? 'not-allowed' : 'pointer'}
      pointerEvents={state === MatchState.SOLD_OUT ? 'none' : 'unset'}
      onClick={onCardClick}
      as='li'
      tabIndex={0}
      transition='border-color .2s ease'
      aria-labelledby='gameCardTitle team1 team2'
      my='10px'
    >
      <Flex flexDirection='column' py='2px'>
        <Flex justify='center' py='4px'>
          {title && (
            <Text id='gameCardTitle' textStyle='bodySmallMedium'>
              {title}
            </Text>
          )}
          {title && subtitle && <Text textStyle='bodySmallMedium'>・</Text>}
          {subtitle && <Text textStyle='bodySmallMedium'>{subtitle}</Text>}
        </Flex>

        <Flex justifyContent='space-around' flexDirection='row' textAlign='center'>
          <Flex justifyContent='center' alignItems='center' flexDirection='column' py='4px'>
            <Image
              src={teamA.logo}
              alt={`${teamA.name} logo`}
              boxSize='56px'
              filter={state === MatchState.SOLD_OUT ? 'grayscale(1)' : 'none'}
              borderRadius='50%'
              height='56px'
              width='56px'
              fallback={<PlaceholderTeamLogo />}
            />
            <Text id='team1' textStyle='caption'>
              {teamA.name}
            </Text>
          </Flex>
          <Flex justifyContent='center' alignItems='center' flexDirection='column' py='4px'>
            <Text textStyle='bodySmallSemibold'>{date}</Text>
            <Text
              textStyle='caption'
              color={state === MatchState.SOLD_OUT ? 'dark.D200' : 'dark.D500'}
            >
              {time}
            </Text>
          </Flex>

          <Flex justifyContent='center' alignItems='center' flexDirection='column' py='4px'>
            <Image
              src={teamB.logo}
              alt={`${teamB.name} logo`}
              boxSize='56px'
              filter={state === MatchState.SOLD_OUT ? 'grayscale(1)' : 'none'}
              borderRadius='50%'
              height='56px'
              width='56px'
              fallback={<PlaceholderTeamLogo />}
            />
            <Text id='team2' textStyle='caption'>
              {teamB.name}
            </Text>
          </Flex>
        </Flex>

        <Divider borderColor='dark.D100' />

        <Flex justifyContent='space-between' alignItems='center' py='4px'>
          <Flex gap={2} alignItems='center'>
            <Badge
              variant={state === MatchState.SOLD_OUT ? 'red' : 'primary'}
              textStyle='bodySmallSemibold'
              alignItems='center'
              px={2}
            >
              {state === MatchState.SOLD_OUT ? (
                <FormattedMessage id='gameCard.soldOut' defaultMessage={'Sold out'} />
              ) : (
                `${new Intl.NumberFormat().format(startingPrice)} ${currency}`
              )}
            </Badge>
            <Text textStyle='bodySmallMedium'>
              <FormattedMessage id='gameCard.from' defaultMessage={'From'} />
            </Text>
          </Flex>
          <Flex gap={1} alignItems='center'>
            <Text
              textStyle='bodySmallRegular'
              color={state === MatchState.SOLD_OUT ? 'dark.D500' : 'green.G500'}
            >
              {stadium}
            </Text>
            <Image
              as={mapPinSVG}
              filter={state === MatchState.SOLD_OUT ? 'grayscale(1)' : 'none'}
              alt='icon representing a pin on a map'
              boxSize={4}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  ) : (
    <Box
      bg='white'
      color={state === MatchState.SOLD_OUT ? 'dark.D500' : 'dark.D900'}
      p={5}
      textAlign='right'
      width={{ base: '100%', md: '392px' }}
      minW='344px'
      borderRadius='16px'
      borderColor='white'
      borderWidth='1px'
      _hover={{ borderColor: state === MatchState.SOLD_OUT ? 'dark.D500' : 'green.G500' }}
      cursor={state === MatchState.SOLD_OUT ? 'not-allowed' : 'pointer'}
      pointerEvents={state === MatchState.SOLD_OUT ? 'none' : 'unset'}
      onClick={onCardClick}
      as='li'
      tabIndex={0}
      transition='border-color .2s ease'
      aria-labelledby='gameCardTitle team1 team2'
      my='10px'
      mx={isMobile ? '0px' : '10px'}
    >
      <Flex flexDirection='column' gap={4}>
        <Flex justify='center' gap={1}>
          {title && (
            <Text id='gameCardTitle' textStyle='bodySmallMedium'>
              {title}
            </Text>
          )}
          {title && subtitle && <Text textStyle='bodySmallMedium'>・</Text>}
          {subtitle && <Text textStyle='bodySmallMedium'>{subtitle}</Text>}
        </Flex>

        <Flex justifyContent='space-around' flexDirection='row' textAlign='center'>
          <Flex justifyContent='center' alignItems='center' flexDirection='column' gap={2}>
            <Image
              src={teamA.logo}
              alt={`${teamA.name} logo`}
              boxSize='56px'
              filter={state === MatchState.SOLD_OUT ? 'grayscale(1)' : 'none'}
              borderRadius='50%'
              height='56px'
              width='56px'
              fallback={<PlaceholderTeamLogo />}
            />
            <Text id='team1' textStyle='caption'>
              {teamA.name}
            </Text>
          </Flex>
          <Flex justifyContent='center' alignItems='center' flexDirection='column' gap={1}>
            <Text textStyle='bodySmallSemibold'>{date}</Text>
            <Text
              textStyle='caption'
              color={state === MatchState.SOLD_OUT ? 'dark.D200' : 'dark.D500'}
            >
              {time}
            </Text>
          </Flex>

          <Flex justifyContent='center' alignItems='center' flexDirection='column' gap={2}>
            <Image
              src={teamB.logo}
              alt={`${teamB.name} logo`}
              boxSize='56px'
              filter={state === MatchState.SOLD_OUT ? 'grayscale(1)' : 'none'}
              borderRadius='50%'
              height='56px'
              width='56px'
              fallback={<PlaceholderTeamLogo />}
            />
            <Text id='team2' textStyle='caption'>
              {teamB.name}
            </Text>
          </Flex>
        </Flex>

        <Divider borderColor='dark.D100' />

        <Flex justifyContent='space-between' alignItems='center' gap={2}>
          <Flex gap={2} alignItems='center'>
            <Badge
              variant={state === MatchState.SOLD_OUT ? 'red' : 'primary'}
              textStyle='bodySmallSemibold'
              alignItems='center'
              px={2}
            >
              {state === MatchState.SOLD_OUT ? (
                <FormattedMessage id='gameCard.soldOut' defaultMessage={'Sold out'} />
              ) : (
                `${new Intl.NumberFormat().format(startingPrice)} ${currency}`
              )}
            </Badge>
            <Text textStyle='bodySmallMedium'>
              <FormattedMessage id='gameCard.from' defaultMessage={'From'} />
            </Text>
          </Flex>
          <Flex gap={1} alignItems='center'>
            <Text
              textStyle='bodySmallRegular'
              color={state === MatchState.SOLD_OUT ? 'dark.D500' : 'green.G500'}
            >
              {stadium}
            </Text>
            <Image
              as={mapPinSVG}
              filter={state === MatchState.SOLD_OUT ? 'grayscale(1)' : 'none'}
              alt='icon representing a pin on a map'
              boxSize={4}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
