import {
  Box,
  Text,
  Image,
  Heading,
  Show,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
} from '@chakra-ui/react';
import { Sponsors } from 'components/Sponsors/Sponsors';
import { FormattedMessage } from 'react-intl';
import heroBanner from '../../../assets/Hero Section Players.png';
import MapPinSVG from 'assets/search.png';
import { useAuthCode } from 'context/AuthCodeContext/AuthCodeContext';

export const Hero = () => {
  const authCode = useAuthCode();
  const qiToken = window.qi.user?.mobile.token;

  console.log(qiToken);
  return (
    <Box
      as='section'
      bg='green.G900'
      color='green.G0'
      textAlign='right'
      aria-labelledby='headingHero'
      pr={{ base: '16px', md: '90px' }}
      pl={{ base: '0', xl: '90px' }}
      width='100vw'
    >
      <HStack maxW='container.xl' mx='auto' justifyContent={{ base: 'end', md: 'space-between' }}>
        <Show above='lg'>
          <Box alignSelf='end'>
            <Image
              alt='Two players of iraqi national team'
              src={heroBanner}
              maxH={{ lg: '704px', xl: '704px' }}
              maxW={{ lg: 'auto', xl: '613px' }}
            />
          </Box>
        </Show>

        <Box
          py='36px'
          display='flex'
          flexDirection='column'
          justifyItems={{ base: 'center', lg: 'end' }}
          alignItems={{ base: 'center', lg: 'end' }}
        >
          <Heading
            id='headingHero'
            as='h1'
            py='10px'
            variant={{ base: 'headingHeroSmall', md: 'headingHero' }}
          >
            <Box>
              <FormattedMessage
                defaultMessage='Find and book tickets to your favorite events'
                id='concerts.hero.heading'
                values={{
                  color: (chunks) => (
                    <Text
                      as='div'
                      display='inline'
                      variant={{ base: 'headingHeroSmall', md: 'headingHero' }}
                      color='green.G50'
                      textAlign={'right'}
                    >
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Box>
            {qiToken === undefined && authCode === null && (
              <Box
                width={{ base: '100vw', md: '80vw', lg: '601px' }}
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='end'
                py='10px'
                my='10px'
              >
                <InputGroup width={{ base: '90%', lg: '100%' }}>
                  <InputLeftElement pointerEvents='none'>
                    <Image src={MapPinSVG} alt='icon of a map pin' width={'80%'} />
                  </InputLeftElement>
                  <Input
                    type='text'
                    placeholder='البحث عن طريق الحفلة ، الفريق ، المكان'
                    backgroundColor={'white'}
                    color={'black'}
                    textAlign={'right'}
                  />
                </InputGroup>
              </Box>
            )}
          </Heading>
          <Show above='lg'>
            <Box pl={'50px'}>
              <Sponsors />
            </Box>
          </Show>
        </Box>
      </HStack>
    </Box>
  );
};
