import WelcomePage from 'pages/welcome/WelcomePage';
import { useAuthCode } from '../../context/AuthCodeContext/AuthCodeContext';
import { LoaderIcon } from 'lucide-react';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { useCopyToClipboard } from '@uidotdev/usehooks';

const LoadingSpinner = () => {
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const hasCopiedText = Boolean(copiedText);
  const { authCode, showWelcome } = useAuthCode();
  const userToken = window.qi.user?.mobile.token;

  if (authCode === null && userToken === null && showWelcome === true) {
    return <WelcomePage />;
  } else {
    return (
      <Flex direction='column' justify='center' align='center' w='100vw' h='80vh'>
        <Box aria-label='جاري التحميل' role='status'>
          <Spinner as={LoaderIcon} w='4em' h='4em' speed='0.8s' />
        </Box>
        <Text fontSize='lg' fontWeight='medium' color='black'>
          جاري التحميل
        </Text>
        {import.meta.env.VITE_APP_BASE_API === 'https://api.digitalzone-dev.net/consumers' && (
          <button
            onClick={() => copyToClipboard(authCode as string)}
            className=' bg-yellow-400 text-black border-2 border-black px-4 py-2 rounded-lg mx-5'
          >
            {hasCopiedText ? 'Copied' : ' copy auth code'}
          </button>
        )}
      </Flex>
    );
  }
};

export default LoadingSpinner;
