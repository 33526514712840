import { Box, Flex, Grid, Heading, Spinner } from '@chakra-ui/react';
import { ConcertCard } from 'components/ConcertCard/ConcertCard';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useUpcomingWorkshopsQuery } from './hooks';

export const Upcoming = () => {
  const navigate = useNavigate();

  const { isLoading, data } = useUpcomingWorkshopsQuery();

  const handleCardClick = (eventKey: string) => {
    navigate('/concert/' + eventKey);
  };
  const numColumns = data && data.items.length >= 3 ? 3 : data && data.items.length;

  return (
    <Box
      as='section'
      bg='BG100'
      py='36px'
      color='dark.D900'
      textAlign='right'
      aria-labelledby='headingUpcoming'
    >
      <Box>
        <Heading
          textAlign={'center'}
          id='headingUpcoming'
          mb='24px'
          variant={{ base: 'headingMedium', md: 'headingLarge' }}
        >
          <FormattedMessage defaultMessage='Upcoming Workshops' id='workshops.upcoming.heading' />
        </Heading>
        {isLoading ? (
          <Flex align='center' justify='center' mt='50px' mb='50px'>
            <Spinner color='green.G50' size='xl' thickness='4px' />
          </Flex>
        ) : data?.items.length ? (
          <Box width={'100vw'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Grid
              templateColumns={{
                base: `repeat(${1}, 1fr)`, // Adjusted to render appropriate number of columns
                md: `repeat(${1}, 1fr)`,
                lg: `repeat(${numColumns}, 1fr)`,
                xl: `repeat(${numColumns}, 1fr)`,
              }}
              justifyContent={'center'}
              as='ul'
              listStyleType='none'
            >
              {data.items.map((workshop) => (
                <div>
                  <ConcertCard
                    {...workshop}
                    key={workshop.id}
                    onCardClick={() => handleCardClick(workshop.eventKey)}
                  />
                </div>
              ))}
            </Grid>
          </Box>
        ) : (
          <Flex align='center' justify='center'>
            <FormattedMessage
              defaultMessage='No upcoming Workshops'
              id='workshops.upcoming.noworkshops'
            />
          </Flex>
        )}
      </Box>
    </Box>
  );
};
