import { CartActions, CartInfo } from './types';

export enum CART_ACTION {
  SET_MATCH_ID = 'SET_MATCH_ID',
  SET_EVENT_ID = 'SET_EVENT_ID',
  SET_EVENT_KEY = 'SET_EVENT_KEY',
  SET_HOLD_TOKEN = 'SET_HOLD_TOKEN',
  UPDATE_TICKETS = 'UPDATE_TICKETS',
  CLEAR_TICKETS = 'CLEAR_TICKETS',
  CLEAR_DATA = 'CLEAR_DATA',
}

export const cartReducer = (currentCartState: CartInfo, action: CartActions): CartInfo => {
  switch (action.type) {
    case CART_ACTION.SET_MATCH_ID:
    case CART_ACTION.SET_EVENT_ID:
    case CART_ACTION.SET_EVENT_KEY:
    case CART_ACTION.SET_HOLD_TOKEN: {
      return {
        ...currentCartState,
        ...action.payload,
      };
    }

    case CART_ACTION.UPDATE_TICKETS: {
      return {
        ...currentCartState,
        tickets: action.payload.chart.selectedObjects.map((label) => {
          const prevSelected = currentCartState.tickets.find((ticket) => ticket.sector === label);
          return prevSelected
            ? prevSelected
            : {
                sector: action.payload.label,
                category: action.payload.category.label,
                categoryKey: action.payload.category.key.toString(),
                price: action.payload.pricing.price,
                formattedPrice: action.payload.pricing.formattedPrice,
                deselect: action.payload.deselect,
                entrance: action.payload.entrance,
              };
        }),
      };
    }

    case CART_ACTION.CLEAR_TICKETS: {
      return {
        ...currentCartState,
        tickets: [],
      };
    }
    case CART_ACTION.CLEAR_DATA: {
      return {
        tickets: [],
      };
    }
  }
};
